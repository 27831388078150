import React from "react";
import { useContext } from "react";
import Contexto from "../context/Contexto";
import "../assets/css/Home.css";
import Poster from "../assets/statics/poster.jpg";
import Curso from "../components/CursoEspecial";
import { useEffect } from "react";
import { Link } from "react-router-dom";
function Home() {
  const { productos, seteos, agregarCarrito } = useContext(Contexto);
  useEffect(() => {
    console.log("productos-->", productos);
  }, [seteos]);
  let compralo = () => {
    agregarCarrito(seteos?.home.ctaUrl);
  };
  return (
    <>
      <div className="todo">
        {seteos && (
          <div
            className="homeHero"
            style={{ background: seteos && seteos?.home.colorin }}
          >
            <div className="contenedorBanner off">
              <img
                src={seteos && seteos.home.poster}
                alt=""
                className="poster-mobile off ppp"
              />
              {seteos.home.target != "compra" ? (
                <a
                  href={seteos.home.ctaUrl}
                  target={seteos.home.target}
                  rel="noreferrer"
                  className="cta ctaDesktop"
                  style={{
                    background: seteos ? seteos.home.ctaColor : "",
                    color: seteos ? seteos.home.ctaTXTColor : "",
                  }}
                >
                  {seteos.home.cta}
                </a>
              ) : (
                <div
                  onClick={compralo}
                  className="cta ctaDesktop"
                  style={{
                    background: seteos ? seteos.home.ctaColor : "",
                    color: seteos ? seteos.home.ctaTXTColor : "",
                  }}
                >
                  {seteos.home.cta}
                </div>
              )}
            </div>

            <img
              src={seteos && seteos.home.posterMobile}
              alt=""
              className="poster on"
            />
            {seteos && (
              <a
                href={seteos.home.ctaUrl}
                target={seteos.home.target}
                rel="noreferrer"
                className="cta on "
                style={{
                  background: seteos ? seteos.home.ctaColor : "",
                  color: seteos ? seteos.home.ctaTXTColor : "",
                }}
              >
                {seteos.home.cta}
              </a>
            )}
          </div>
        )}
        <div className="contenedorEspecial">
          {productos &&
            /*eslint-disable */
            productos.map((item, i) => {
              if (item.grupo === "fjs") {
                /*eslint-disable */

                return <Curso key={i} {...item}></Curso>;
              }
            })}

          {productos &&
            /*eslint-disable */

            productos.map((item, i) => {
              if (item.grupo === "fsd") {
                /*eslint-disable */
                return <Curso key={i} {...item}></Curso>;
              }
            })}
        </div>
      </div>
    </>
  );
}

("https://www.youtube.com/embed/XpSdD63j7Y4?si=axgj-ZmbVUJN5MTc&amp;controls=0");

export default Home;
