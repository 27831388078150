import React, { useEffect } from "react";

function CarritoItem(props) {
  const { url, nombre, eliminar, logo, colorin, cambiaPrecio, planes } = props;
  // useEffect(() => {
  //   {
  //     //planes.map((option) => option.seleccionado && cambiaPrecio(url, option));
  //   }
  // }, []);

  const handleChange = (e) => {
    //console.log(url.substring(1), e.target.value);
    let leyenda = null;
    planes.map(
      (option) => option.costo == e.target.value && (leyenda = option)
    );
    cambiaPrecio(url, leyenda);
  };

  return (
    <>
      <div className="soportePaquete" style={{ background: colorin }}>
        <div className="loguitoCositas">
          <img
            src={`https://escueladevrock.com/${logo}`}
            alt="DevRock"
            className="img-paquete"
          />{" "}
        </div>

        <div className="titulitos">
          <p className="tituloNombre">{nombre}</p>

          {planes.length > 1 ? (
            <>
              <label for="plan">Seleccioná tu plan:</label>
              <br />
              <select
                id="plan"
                name="plan"
                onChange={handleChange}
                className="planes"
              >
                {planes.map((option) =>
                  option.seleccionado ? (
                    <option key={option.id} value={option.costo} selected>
                      {option.nombre}
                    </option>
                  ) : (
                    <option key={option.id} value={option.costo}>
                      {option.nombre}
                    </option>
                  )
                )}
              </select>
            </>
          ) : (
            planes[0].nombre
          )}
        </div>
        <button
          onClick={() => {
            eliminar(nombre);
          }}
          className="btn-loco ajuste"
        >
          X
        </button>
      </div>
    </>
  );
}

export default CarritoItem;
