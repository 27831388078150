import React from "react";
import { useContext } from "react";
import Contexto from "../context/Contexto";
import { Link } from "react-router-dom";
import "../assets/css/CursoEspecial.css";
import ImgMarcoBTN from "./ImgMarcoBTN";

function CursoEspecial(props) {
  const {
    nombre,
    logo,
    descripcion,
    precio,
    url,
    oferta,
    descuento,
    ofertaInfo,
    colorin,
    grupo,
    planes,
    duracion,
    img,
  } = props;

  const { Griseado } = useContext(Contexto);

  let precioFinal = precio;
  if (oferta !== "no") {
    let des = 100 - descuento;
    precioFinal = (precio * des) / 100;
  }

  return (
    <>
      {/* <div className="curso" style={{ background: colorin }}> */}
      <div className="cursoX">
        <img
          src={`${img}`}
          alt=""
          className="curso__img"
          onClick={() => {
            Griseado(url);
          }}
        />
        <div className="infoyfoto">
          <div className="curso_informacion">
            <div
              className="ver"
              onClick={() => {
                Griseado(url);
              }}
            >
              <h1 className="curso__titulo">{nombre}</h1>
            </div>
            <p className="curso__descripcion">{descripcion}</p>

            {oferta !== "no" && (
              <>
                <p className="curso--oferta">
                  <del>AR$ {precio}</del> {ofertaInfo}
                </p>
              </>
            )}
          </div>

          {grupo != "free" ? (
            <div className="curso__precio">
              <div
                className="ver"
                onClick={() => {
                  Griseado(url);
                }}
              >
                Ver programa
              </div>
              <span className="preciou"> AR$ {precio} </span>
              <span className="duracion"> X {duracion} meses </span>
              <ImgMarcoBTN
                fill="black"
                Hover="white"
                width="100px"
                height="30px"
                nombre={url}
                accion={"carrito"}
                className="hand"
              >
                Comprar
              </ImgMarcoBTN>
            </div>
          ) : (
            <>
              <div className="curso__precio">
                <button
                  className="ver btn-loco"
                  onClick={() => {
                    Griseado(url);
                  }}
                >
                  LO QUIERO
                </button>
                <h5 className="cursoGrati">CURSO GRATI'</h5>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default CursoEspecial;
