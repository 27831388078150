import React, { useEffect } from "react";
import { initializeApp } from "firebase/app";
import { useLocation } from "react-router-dom";
import jsPDF from "jspdf";

import {
  getDatabase,
  ref,
  remove,
  onValue,
  onChildAdded,
  update,
  push,
} from "firebase/database";
import {
  getAuth,
  GoogleAuthProvider,
  onAuthStateChanged,
  signInWithRedirect,
  getRedirectResult,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";

import Reducer from "./Reducer";
import Contexto from "./Contexto";

//import axios from "axios";
import { useState, useReducer } from "react";
// Import the functions you need from the SDKs you need
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's DEVROCK Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDg_k_MorkfxsWeOzgVojUULDKjtUYaXQg",
  authDomain: "edrfinal.firebaseapp.com",
  databaseURL: "https://edrfinal.firebaseio.com",
  projectId: "edrfinal",
  storageBucket: "edrfinal.appspot.com",
  messagingSenderId: "771450384668",
  appId: "1:771450384668:web:31c8ebeb5661fb04f14ad9",
};

// Your web app's TAC Firebase configuration
// TALLERITO
// const firebaseConfig = {
//   apiKey: "AIzaSyAJRxD6-3kU94I2JblDYDv-jpYmFC9zJeY",
//   authDomain: "talleres-a-color.firebaseapp.com",
//   databaseURL: "https://talleres-a-color-default-rtdb.firebaseio.com",
//   projectId: "talleres-a-color",
//   storageBucket: "talleres-a-color.appspot.com",
//   messagingSenderId: "946136349850",
//   appId: "1:946136349850:web:5feb5362bd71c2e6087323",
//   measurementId: "G-YX7QNZ1WM3",
// };

// Initialize Firebase

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const db = getDatabase();
const examenes = ref(db, "/examenes");
const descuentis = ref(db, "/descuentos");
const seteos = ref(db, "/seteos");
const premios = ref(db, "/premios");

export default function UsarContexto(props) {
  /*eslint-disable */
  let cantidad = 0;
  const location = useLocation();

  const { children } = props;
  const [estado, setEstado] = useState([]);
  const initialState = {
    productos: [],
    carrito: [],
    winter: [],
    cliente: null,
    usuario: null,
    seteos: null,
    isLoader: false,
    isEntrando: false,
    isCreando: false,
    creando: false,
    cursoscomprados: [],
    totales: [],
    verCurso: null,
    influencers: [],
    premios: [],
    aDondeVoy: null,
    winner: -1,

    mensajes: { mensaje: "" },
  };

  const [state, dispatch] = useReducer(Reducer, initialState);
  useEffect(() => {
    dispatch({ type: "ISLOAD", payload: true });
    traemeWinter();
    onValue(seteos, (snap) => {
      dispatch({ type: "MIS_SETEOS", payload: snap.val() });
      dispatch({ type: "PLANES", payload: snap.val().planes });
    });
    listameProductos();

    onValue(descuentis, (snap) => {
      dispatch({ type: "INFLUENCERS", payload: snap.val() });
    });

    onValue(premios, (snap) => {
      dispatch({ type: "PREMIOS", payload: snap.val() });
    });
    onValue(examenes, (snap) => {
      dispatch({ type: "EXAMENES", payload: snap.val() });
    });

    //contarUsuarios("cyberzombie");
  }, []);

  useEffect(() => {}, [state.seteos]);
  const traemeWinter = () => {
    const summer = ref(db, "/summer");
    onValue(summer, (snap) => {
      const valores = snap.val();
      // console.log(valores);
      dispatch({ type: "DAME_WINTER", payload: valores });
    });
  };
  const listameProductos = async () => {
    const cursos = ref(db, "/cursosInfo/");
    onValue(cursos, (snap) => {
      const valores = snap.val();
      let claves = Object.keys(valores);
      for (let i = 0; i < claves.length; i++) {
        let clave = claves[i];
        dispatch({ type: "LISTAME_PRODUCTOS", payload: valores[clave] });
      }
      const params = new URLSearchParams(location.hash);
      let parametros = params.entries();
      //let decode = atob(location.hash.substr(1));
      let decode = "";
      if (decode !== "" && location.pathname != "/ver") {
        autoCarga(decode);
      }
      dispatch({ type: "ISLOAD", payload: false });
    });
    recuperoCarritoExistente();
  };

  //LOGUEO DE USUARIO
  // const loguearUsuario = () => {
  //   auth.languageCode = "es";
  //   const provider = new GoogleAuthProvider();
  //   provider.setCustomParameters({
  //     prompt: "select_account",
  //   });
  //   signInWithPopup(auth, provider).then((result) => {
  //     let usuarioLogueado = {
  //       uid: result.user.uid,
  //       username: result.user.displayName,
  //       profile_picture: result.user.photoURL,
  //       email: result.user.email,
  //     };
  //     dispatch({ type: "USUARIO_LOGUEADO", payload: usuarioLogueado });
  //     buscarInfoCliente(usuarioLogueado.uid);
  //     mandarMensajes(`Holi ${usuarioLogueado.username}`);
  //   });
  // };

  const googleProvider = new GoogleAuthProvider();
  // googleProvider.addScope("https://www.googleapis.com/auth/userinfo.email");
  // googleProvider.addScope("https://www.googleapis.com/auth/userinfo.profile");

  const firebaseLogin = async () => {
    try {
      auth.languageCode = "es";
      let credenciales = await signInWithPopup(auth, new GoogleAuthProvider());
    } catch (error) {
      return console.log(error);
    }
    return true;
  };

  const loguearUsuario = () => {
    firebaseLogin();
  };

  //PERSISTENCIA DE USUARIO
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        let usuarioLogueado = {
          uid: user.uid,
          username: user.displayName,
          profile_picture: user.photoURL,
          email: user.email,
        };

        dispatch({ type: "USUARIO_LOGUEADO", payload: usuarioLogueado });
        buscarInfoCliente(usuarioLogueado.uid);
      }
    });
  }, []);

  // DESLOGUEO DE USUARIO
  const desconectarUsuario = () => {
    signOut(auth).then(() => {
      if (state.usuario.username == null) {
        mandarMensajes(`Adios`);
      } else {
        mandarMensajes(`Adios ${state.usuario.username}`);
      }

      dispatch({ type: "DESLOGUEAR_USUARIO", payload: null });
      dispatch({ type: "INFO_CLIENTE", payload: null });
    });
  };

  // BUSCAR DATA DE UN USUARIO

  const buscarInfoCliente = (uid) => {
    const refCliente = ref(db, "/usuarios/" + uid);
    onValue(refCliente, (snap) => {
      dispatch({ type: "INFO_CLIENTE", payload: snap.val() });
      if (snap.val().vencimientos) {
        controlarVencidos(snap.val().vencimientos);
      }
      if (snap.val().username == undefined) {
        mandarMensajes(`Holi, recordá ir a tu panel para agregar tu info`);
      } else {
        mandarMensajes(`Holix ${snap.val().username}`);
      }
    });
  };

  const buscarDataCliente = (uid) => {
    const refCliente = ref(db, "/usuarios/" + uid);
    onValue(refCliente, (snap) => {
      dispatch({ type: "INFO_CLIENTE", payload: snap.val() });
    });
  };

  // CONTAR USUARIO primer linea de codigo para el Panel de Rana <3
  const contarUsuarios = (curso) => {
    console.time("usando contar usuarios");
    const refUsuarios = ref(db, "/usuarios/");
    // pasa uno por uno los usuarios que bajo de la base
    let cantidad = 0;
    onChildAdded(refUsuarios, (snap) => {
      // dispatch({ type: "INFO_CLIENTE", payload: snap.val() });
      const Usuario = snap.val();
      // const array = Object.entries(usuarios);

      // const guitarKeys = Object.keys(myGuitar);
      // let arrayGuitar = [];
      // const convertedObjectToArray =
      //   arrayGuitar.length === 0
      //     ? guitarKeys.forEach((key) =>
      //         arrayGuitar.push(key + ": " + myGuitar[key])
      //       )
      //     : (arrayGuitar = []);

      // console.log(arrayGuitar, "2");

      // if (
      //   // Usuario.designseason2 ||
      //   // Usuario.designseason2 ||
      //   // Usuario.designseason3 ||
      //   // Usuario.designseason4

      //   Usuario.js
      // ) {
      //   if (Usuario.react) {
      //     cantidad++;
      //     console.log(Usuario.email);
      //   }
      // }
      // if (Usuario.winterhack23 && Usuario.summerhack23) {
      //   //cantiad++;
      //   if (Usuario.email) {
      //     console.log(
      //       `{${Usuario.email}:"${Usuario.uid}"} ( - Summerhack23)`
      //     );
      //   }
      // }

      // if (Usuario.winterhack23 && Usuario.summerhack2022) {
      //   //cantiad++;
      //   if (Usuario.email) {
      //     console.log(
      //       `{${Usuario.email}:"${Usuario.uid}"} ( - Summerhack2022)`
      //     );
      //   }
      // }

      //ME DA LOS EMAILS
      if (Usuario[curso]) {
        cantidad++;

        console.log(`${Usuario.email}, ${Usuario.uid}`);
      }
      //   if (Usuario) {
      //     cantiad++;
      //     //console.log(`${cantiad} - ${Usuario.email} - uid - ${Usuario.uid}`);

      //     if (
      //       Usuario.hasOwnProperty("retosDS") &&
      //       Usuario.retosDS.retos2[4].url
      //     ) {
      //       cantiad++;
      //       console.log(
      //         `${cantiad} usuario: ${Usuario.username}, ${Usuario.email}, --->x:${Usuario.retosDS.retos2[4].url}`
      //       );
      //     }
      //}
      //   if (Usuario.discord && Usuario.discord.targetUser == 668588231935131659)
      //     console.log(Usuario);
      // }
      // ME DA LOS LOMKEDIN
      // if (Usuario.linkedin != undefined && Usuario[curso]) {
      //   cantiad++;
      //   console.log(`${Usuario.linkedin}`);
      // }
      //       );
      //CAMBIO VENCIMIENTOS LOCO
      // if (Usuario[curso]) {
      //   console.log(`${Usuario.email},cantiad:  cambiado`);
      //   let refita = ref(
      //     db,
      //     "/usuarios/" + Usuario.uid + "/vencimientos/" + curso + "/"
      //   );
      //   update(refita, { termina: 1697826224133 });
      //   console.log(Usuario.username, curso);
      // }
      // if (Usuario[curso] && Usuario.examenes != undefined) {
      //   if (Usuario.examenes[curso]) {
      //     console.log(Usuario.uid, Usuario.email, Usuario.examenes[curso]);
      //   }
      // }
    });

    console.timeEnd("usando contar usuarios->");
    console.log("Cantidad de alumnnxs en esta busqueda:", cantidad);
  };

  /////////////////////////////////////CARRITO AGREGAR y esouuu
  const recuperoCarritoExistente = () => {
    let carritoGuardado = localStorage.getItem("carrito");
    carritoGuardado = JSON.parse(carritoGuardado);
    if (carritoGuardado) {
      dispatch({ type: "CARRITO_INICIAL", payload: carritoGuardado });
    }
  };
  const agregarCarrito = (payload) => {
    dispatch({ type: "AGREGAR_CARRITO", payload: payload });
    mandarMensajes(`Agregaste al carrito ${payload}`);
  };

  const eliminarCarrito = (payload) => {
    dispatch({ type: "ELIMINAR_CARRITO", payload: payload });
    mandarMensajes(`Eliminaste de carrito ${payload}`);
  };
  //controlo cambios en el carrito y actualio el localstorage
  useEffect(() => {
    localStorage.setItem("carrito", JSON.stringify(state.carrito));
  }, [state.carrito]);
  const controlarVencidos = (cursos) => {
    // for (let item of cursos) {
    //   console.log(item, "loq que tiene vencimientos");
    // }
    let claves = Object.keys(cursos); // claves = ["nombre", "color", "macho", "edad"]
    for (let i = 0; i < claves.length; i++) {
      let clave = claves[i];
      //console.log(clave, cursos[clave]);
      dispatch({
        type: "CURSO_COMPRADO",
        payload: controlarVencimiento(clave, cursos[clave].termina),
      });
    }
  };

  const controlarVencimiento = (quien, vencimiento) => {
    let hoy = new Date();
    let ahora = hoy.getTime();
    let quedan = vencimiento - ahora;
    let result = quedan / 1000 / 60 / 60 / 24;
    // let cuenta = 1000 * 60 * 60 * 24 * 365;
    // console.log(ahora + cuenta, "en un año");
    let leQuedan = Math.round(result);
    let vencido = true;
    if (leQuedan < 1) {
      vencido = false;
    }
    return { curso: quien, restantes: leQuedan, estado: vencido };
  };
  let borrarDatos = (referencia) => {
    let referenciaFinal = ref(db, referencia);
    remove(referenciaFinal);
  };
  let guardarDatos = (referencia, objeto) => {
    let referenciaFinal = ref(db, referencia);
    update(referenciaFinal, objeto);
    mandarMensajes(`Guardé bien tus datos.`);
  };
  let autoCarga = (dato) => {
    dispatch({ type: "AGREGAR_PACK", payload: dato });
    mandarMensajes(`Agregaste un pack carrito`);
  };
  const mandarMensajes = (payload) => {
    var fecha = new Date();

    dispatch({
      type: "NANDAR_MENSAJE",
      payload: { mensaje: payload, tiempo: fecha },
    });
  };
  const Griseado = (payload) => {
    dispatch({ type: "DONDE_VOY", payload: payload });
  };

  const estamosEntrando = (payload) => {
    dispatch({ type: "ESTAMOS_ENTRANDO", payload: payload });
    dispatch({ type: "ESTAMOS_CREANDO", payload: false });
  };

  const estamosCreando = (payload) => {
    dispatch({ type: "ESTAMOS_CREANDO", payload: payload });
    dispatch({ type: "ESTAMOS_ENTRANDO", payload: false });
  };

  const buscarDataCurso = (payload) => {
    const refCurso = ref(db, "/cursos/" + payload);
    onValue(refCurso, (snap) => {
      let listadoVideos = [];
      let claves = Object.keys(snap.val());
      for (let i = 0; i < claves.length; i++) {
        let clave = claves[i];
        listadoVideos.push(snap.val()[clave]);
      }
      dispatch({ type: "VER_CURSO", payload: listadoVideos });
      //salvarProgreso(payload, listadoVideos);
      if (state.cliente.hasOwnProperty(`vistos_${payload}`)) {
        actualizaProgresosViejos(payload, listadoVideos);
      }
    });
  };

  const actualizaProgresosViejos = (curso, videosCurso) => {
    let vistosViejos = state.cliente["vistos_" + curso];
    let claves = Object.keys(vistosViejos); // claves = ["nombre", "color", "macho", "edad"]
    let data = {
      total: 0,
      ultimaVisita: Date.now(),
      videosVistos: [],
    };
    for (let i = 0; i < claves.length; i++) {
      let clave = claves[i];
      let url = vistosViejos[clave].url;
      let elemento = data.videosVistos.includes(url);
      if (!elemento) {
        data.videosVistos.push(vistosViejos[clave].url);
      }
    }
    let puntaje = videosCurso[0].puntaje;
    data.total = (100 / parseInt(puntaje)) * data.videosVistos.length;

    guardarDatos(`usuarios/${state.cliente.uid}/progreso/${curso}`, data);
    borrarDatos(`usuarios/${state.cliente.uid}/vistos_${curso}`);
  };

  const salvarProgreso = (curso, data) => {
    let armado = `usuarios/${state.cliente.uid}/progreso/`;
    let referenciaFinal = ref(db, armado);
    update(referenciaFinal, { [curso]: data });
  };
  const bajarCertificado = (curso) => {
    let urlx =
      "https://image-charts.com/chart?cht=qr&chl=https%3A%2F%2Fcodearock.com%2Fcertificados%2F" +
      state.usuario.uid +
      "&chs=100x100";
    var canvas = document.getElementById("micanvas");
    var ctx = canvas.getContext("2d");
    var img = new Image();
    img.src = urlx;
    img.crossOrigin = "Anonymous";
    img.onload = () => {
      ctx.drawImage(img, 0, 0);
      let dataURL = canvas.toDataURL();

      bajarCertificadou({
        curso: curso,
        uidx: state.usuario.uid,
        userNombre: state.cliente.username,
        mail: state.usuario.email,
        qr: dataURL,
      });
    };
  };

  const canjearCursos = (dato) => {
    let keyRegalo = dato.codigo;
    const cursoc = ref(db, `regalitos/${dato.codigo}`);
    try {
      onValue(cursoc, (snap) => {
        const regalin = snap.val();
        if (regalin.Hay && regalin.regaloKey != "Este código ya fue usado") {
          dameMiCurso({ pibe: state.usuario.uid, cursin: regalin.Cursos });
          quitarRegalo(keyRegalo);
        } else {
          mandarMensajes(`Este código ya fue usado.`);
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  const quitarRegalo = (dato) => {
    var refeQuitar = ref(db, `regalitos/${dato}`);
    const tiempoTranscurrido = Date.now();
    const hoy = new Date(tiempoTranscurrido);
    const hora = hoy.toLocaleTimeString();
    const fecha = hoy.toDateString();
    const fechin = `${fecha} - ${hora}`;
    try {
      update(refeQuitar, {
        Hay: false,
        canjeadoPor: state.usuario.uid,
        canjeadoEl: fechin,
        regaloKey: "Este código ya fue usado",
      });

      mandarMensajes(`Felicitaciones! canjeaste tu regalo.;)`);
    } catch (err) {
      console.log(err);
    }
  };

  const agregarPendientes = (dato) => {
    let refUsuario = ref(db, `usuarios/${state.usuario.uid}`);
    let cursosPendietes = [];

    dato.cursin.map((item, i) => {
      let obj = { curso: item[0].url.substr(1), meses: item[0].meses };
      cursosPendietes.push(obj);
    });
    console.log("cargamos a:", state.usuario.uid, cursosPendietes);
    update(refUsuario, { cursosPendietes });
  };

  const dameMiCurso = (dato) => {
    let refUsuario = ref(db, `usuarios/${state.usuario.uid}`);
    let refUsuarioVen = ref(db, `usuarios/${state.usuario.uid}/vencimientos/`);
    let tiempo = dato.meses ? dato.meses * 30 : 365;
    let cuenta = 1000 * 60 * 60 * 24 * tiempo;
    let hoy = new Date();
    let ahora = hoy.getTime();
    let anio = ahora + cuenta;
    const restan = state.cliente.cursosPendietes.filter(
      (item) => item.curso != dato.cursin[0]
    );
    console.log("restan---->:", restan, "dato.cursin[0]", dato.cursin[0]);

    try {
      for (const prop in dato.cursin) {
        update(refUsuario, {
          [dato.cursin[prop]]: true,
          cursosPendietes: restan,
        });
        update(refUsuarioVen, {
          [dato.cursin[prop]]: { termina: anio },
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const bajarCertificadou = (dato) => {
    const { curso, userNombre, mail, qr, uidx } = dato;

    const cursoc = ref(db, `/certificados/${curso}`);

    onValue(cursoc, (snap) => {
      const cer = snap.val();
      var doc = new jsPDF("landscape", "mm");
      doc.addImage(cer, "PNG", 0, 0, 297, 210);
      doc.addImage(qr, "PNG", 20, 130, 54, 54);
      doc.setFontSize(40);
      doc.setFont("helvetica");
      //doc.addImage(imgData, 'JPEG', 0, 0, width, height);

      //doc.setFontType("light");
      doc.setTextColor(79, 79, 79);
      doc.text(25, 80, userNombre);
      doc.setFontSize(15);
      doc.text(25, 88, mail);
      // doc.setFontType("bold");
      doc.text(25, 193, _hoyFecha());
      doc.setFontSize(10);
      doc.text(25, 204, uidx);
      const nombre = "DevRockCertifica-" + curso + ".pdf";
      doc.save(nombre);
    });
  };
  const _hoyFecha = () => {
    function addZero(i) {
      if (i < 10) {
        i = "0" + i;
      }
      return i;
    }

    var hoy = new Date();
    var dd = hoy.getDate();
    var mm = hoy.getMonth() + 1;
    var yyyy = hoy.getFullYear();
    dd = addZero(dd);
    mm = addZero(mm);
    return dd + "/" + mm + "/" + yyyy;
  };

  const cambiaPrecio = (curso, precio) => {
    dispatch({
      type: "CAMBIA_PRECIO",
      payload: { curso: curso, precio: precio },
    });
    if (precio.leyenda) {
      mandarMensajes(precio.leyenda);
    }
  };

  // Login con EMAIL
  let crearUsuarioConEmail = (email, password) => {
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        if (user) {
          let usuarioLogueado = {
            uid: user.uid,
            username: user.displayName,
            profile_picture: user.photoURL,
            email: user.email,
          };
          dispatch({ type: "USUARIO_LOGUEADO", payload: usuarioLogueado });
        }

        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ..Firebase: Error (auth/email-already-in-use).

        if (errorCode == "auth/email-already-in-use") {
          mandarMensajes(`Este email ya tiene cuenta, usá otro porfis :)`);
        }
      });
  };

  //loginUsuarioConEmail
  let loginUsuarioConEmail = (email, password) => {
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        if (user) {
          let usuarioLogueado = {
            uid: user.uid,
            username: user.displayName,
            profile_picture: user.photoURL,
            email: user.email,
          };
          dispatch({ type: "USUARIO_LOGUEADO", payload: usuarioLogueado });
        }
        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        if (error.message == "Firebase: Error (auth/wrong-password).") {
          mandarMensajes(`Contraseña o email incorrectos`);
        }

        if (errorCode == "auth/user-not-found") {
          mandarMensajes(`Tu email es incorrecto o todavía no está registrado`);
        }
      });
  };
  //recuperar password
  let resetPassword = (email) => {
    const auth = getAuth();
    sendPasswordResetEmail(auth, email)
      .then(() => {
        // Password reset email sent!
        // ..
        mandarMensajes(`¡Listo! Envié un link a tu correo.`);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ..

        if (error.message == "Firebase: Error (auth/missing-email).") {
          mandarMensajes(`Ups, ese email no está registrado en la web`);
        }
      });
  };

  let intento = (intento) => {
    const tempoTranscurrido = Date.now();
    const hoy = new Date();
    let adivina = sectumsempra(intento.toLocaleLowerCase());
    dispatch({ type: "INTENTO_PASCUAS", payload: adivina });
    guardarIntentos(state.cliente.uid, {
      email: state.cliente.email,
      uid: state.cliente.uid,
      intento: intento,
      fecha: hoy.toLocaleString(),
    });
  };

  let sectumsempra = (key) => {
    const splittedWord = key.toLowerCase().split("");
    const codes = splittedWord.map(
      (letter) =>
        `${
          String(letter).charCodeAt(0) * (14983 * 14831 * 14831 * 14831 * 14831)
        }}`
    );
    return codes.join("");
  };
  let guardarIntentos = (email, objeto) => {
    let referenciaFinal = ref(db, "intentos/" + email);
    push(referenciaFinal, objeto);
    //mandarMensajes(`Guardé bien tus datos.`);
  };
  let guardarGanador = (intento) => {
    const hoy = new Date();
    let referenciaFinal = ref(db, "premios/llaves/" + state.winner);
    let win = {
      nombre: state.cliente.username,
      email: state.cliente.email,
      intento: intento,
      uid: state.cliente.uid,
      fecha: hoy.toLocaleString(),
    };

    update(referenciaFinal, win);
  };

  const activaCyberZombie = () => {
    let refUsuario = ref(db, `usuarios/${state.usuario.uid}`);
    let refUsuarioVen = ref(db, `usuarios/${state.usuario.uid}/vencimientos/`);

    mandarMensajes("Activamos tu pack zombie! :)");
    let cuenta = 1000 * 60 * 60 * 24 * 365;
    let hoy = new Date();
    let ahora = hoy.getTime();
    let anio = ahora + cuenta;
    let objeto = {
      firebase: true,
      html: true,
      js: true,
      jspractico: true,
      nextjs: true,
      python: true,
      react: true,
      ui: true,
      ux: true,
      cyberzombie: false,
    };

    let vencimientos = {
      firebase: {
        termina: anio,
      },
      html: {
        termina: anio,
      },
      js: {
        termina: anio,
      },
      jspractico: {
        termina: anio,
      },
      nextjs: {
        termina: anio,
      },
      python: {
        termina: anio,
      },
      react: {
        termina: anio,
      },
      ui: {
        termina: anio,
      },
      ux: {
        termina: anio,
      },
      cyberzombie: {
        termina: ahora,
      },
    };
    update(refUsuario, objeto);
    update(refUsuarioVen, vencimientos);
  };
  return (
    <Contexto.Provider
      value={{
        verCurso: state.verCurso,
        productos: state.productos,
        carrito: state.carrito,
        seteos: state.seteos,
        winter: state.winter,
        usuario: state.usuario,
        cliente: state.cliente,
        isEntrando: state.isEntrando,
        isCreando: state.isCreando,
        influencers: state.influencers,
        cursoscomprados: state.cursoscomprados,
        examenes: state.examenes,
        isLoader: state.isLoader,
        winner: state.winner,
        premios: state.premios,
        mensajes: state.mensajes,
        aDondeVoy: state.aDondeVoy,
        guardarGanador,
        intento,
        dameMiCurso,
        cambiaPrecio,
        bajarCertificado,
        salvarProgreso,
        buscarDataCurso,
        autoCarga,
        Griseado,
        agregarPendientes,
        mandarMensajes,
        listameProductos,
        guardarDatos,
        agregarCarrito,
        eliminarCarrito,
        loguearUsuario,
        desconectarUsuario,
        buscarInfoCliente,
        canjearCursos,
        buscarDataCliente,
        crearUsuarioConEmail,
        loginUsuarioConEmail,
        resetPassword,
        estamosEntrando,
        estamosCreando,
        activaCyberZombie,
      }}
    >
      {children}
      <canvas
        height="180px"
        width="180px"
        id="micanvas"
        style={{ display: "none" }}
      ></canvas>
    </Contexto.Provider>
  );
}
