import React, { useContext, useEffect, useState } from "react";
import "../assets/css/MisCursos.css";
import imgTusCursos from "../assets/statics/estosSonTusCursos.png";
import noTenesCursos from "../assets/statics/noTenesCursos.png";
import Contexto from "../context/Contexto";
import MiCurso from "../components/MiCurso";
import { Link } from "react-router-dom";
import PH from "../assets/statics/programashibridos.png";
import MiCursoPendiente from "../components/MiCursoPendiente";
const MisCursos = () => {
  const { cliente, cursoscomprados, productos } = useContext(Contexto);
  const [tusCursos, setTusCursos] = useState([]);
  const [tusProgramas, setTusProgramas] = useState([]);

  const [pendientes, setPendientes] = useState([]);

  const ConstruyeMisCursos = () => {
    let claves = Object.keys(cursoscomprados);
    let resultado = [];
    let programas = [];
    for (let i = 0; i < claves.length; i++) {
      if (cursoscomprados[claves[i]].info) {
        if (!cursoscomprados[claves[i]].info.esPrograma) {
          resultado.push(cursoscomprados[claves[i]]);
        } else {
          programas.push(cursoscomprados[claves[i]]);
        }
      }
    }
    resultado.sort((a, b) => {
      if (a.info.ordenFront > b.info.ordenFront) {
        return 1;
      }
      if (a.info.ordenFront < b.info.ordenFront) {
        return -1;
      }

      return 0;
    });
    setTusProgramas(programas);
    setTusCursos(resultado);
  };
  useEffect(() => {
    ConstruyeMisCursos();
    if (cliente?.cursosPendietes) setPendientes(cliente.cursosPendietes);
  }, [cliente]);
  return (
    <>
      {pendientes.length > 0 && (
        <>
          <h3>Tus cursos comprados pendientes</h3>
          <div className="contenedorMisCursos">
            {pendientes.map((item, i) => (
              <MiCursoPendiente {...item}></MiCursoPendiente>
            ))}
          </div>
        </>
      )}

      {cliente && tusCursos && (
        <>
          {tusCursos.length ? (
            <img src={imgTusCursos} alt="" className="imgTitCursos" />
          ) : (
            <>
              <img src={noTenesCursos} alt="" className="imgTitCursos" />
              <h3 className="txtLetrita">
                Pero no hay drama, podés sumarte a alguno de los cursos gratis
                ya mismooo <Link to={"/cursosgratis"}>aqui</Link>
              </h3>
            </>
          )}

          <div className="contenedorMisCursos">
            {tusCursos &&
              tusCursos.map((item, i) => (
                <MiCurso
                  key={i}
                  {...item}
                  totales={cliente.progreso}
                  examen={cliente.examenes}
                ></MiCurso>
              ))}
          </div>

          {tusProgramas.length > 0 && (
            <>
              <img src={PH} className="tituloH" />

              <div className="programas">
                {tusProgramas.map((item, i) => (
                  <MiCurso
                    key={i}
                    {...item}
                    totales={cliente.progreso}
                    examen={cliente.examenes}
                  ></MiCurso>
                ))}
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default MisCursos;
